import { render, staticRenderFns } from "./MainDashboard.vue?vue&type=template&id=82f867d0&scoped=true"
import script from "./MainDashboard.vue?vue&type=script&lang=js"
export * from "./MainDashboard.vue?vue&type=script&lang=js"
import style0 from "./MainDashboard.vue?vue&type=style&index=0&id=82f867d0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82f867d0",
  null
  
)

export default component.exports