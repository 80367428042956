import Vue from 'vue'

const eventsHub = new Vue();
import IdleVue from 'idle-vue'
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 10000
})

import App from './App.vue'
import router from './router'
import store from './store'
import UserStore from "./store/UserStore"
import axios from 'axios';

import Vuex from 'vuex'
Vue.use(Vuex)

import Buefy from 'buefy'
import 'bulma/css/bulma.css'
import 'buefy/dist/buefy.css'
// import './assets/css/buefy/buefy.css'
import './assets/styles/veecli.css'

// IntroJS
import './assets/introjs/introjs.min.css'
import * as introJs from './assets/introjs/intro.min.js'
// Vue.use(introJs)

// import { library } from '@fortawesome/fontawesome-svg-core';
// import { fas } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// Vue.component('font-awesome-icon', FontAwesomeIcon);
// library.add(fas)

Vue.use(Buefy, {
  defaultIconPack: 'fas',
  // defaultIconComponent: 'font-awesome-icon',
})

import FloatingVue from 'floating-vue'
Vue.use(FloatingVue)
import 'floating-vue/dist/style.css'

import Meta from 'vue-meta'
Vue.use(Meta);

import UUID from "vue-uuid";
Vue.use(UUID);

import moment from 'moment'

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(value).format('MM/DD/YYYY')
  }
});

Vue.filter('formatDateTime', function(value) {
  if (value) {
    return moment(value).format('MM/DD/YYYY hh:mm a')
  }
});

Vue.filter('formatDateYYYYMMDD', function(value) {
  if (value) {
    if (typeof(value) == "string") {
      value = new Date(value)
    }    
    return moment(value).format('YYYY-MM-DD')
  }
});

Vue.filter('formatDateMonthYear', function(value) {
  if (value) {
    if (typeof(value) == "string") {
      value = new Date(value)
    }    
    return moment(value).format('MMMM YYYY')
  }
});

Vue.filter('formatDateToMySQL', function(value) {
  if (value) {
    if (typeof(value) == "string") {
      value = new Date(value)
    }    
    return value.getFullYear() + '-' + String(value.getMonth() + 1).padStart(2, '0') + '-' + String(value.getDate()).padStart(2, '0')
  }  
})

Vue.filter('formatMonthYear', function(value) {
  if (value) {
    return moment(value).format('MMMM YYYY')
  }
})

Vue.filter('formatMonthName', function(value) {
  if (value) {
    return moment(value).format('MMMM')
  }
})

Vue.filter('formatFullYear', function(value) {
  if (value) {
    return moment(value).format('YYYY')
  }
})
// const currencyFormatter = new Intl.NumberFormat('en-US', {
//   style: 'currency',
//   currency: 'USD',
//   minimumFractionDigits: 2
// })


const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: UserStore.getters.getCountryCurrencyCode
})

Vue.filter('formatCurrency', function(value) {
  if (isNaN(value)) {
    value = 0
  } 
  return currencyFormatter.format(value)
})

Vue.filter('formatCurrency3Decimal', function(value) {
  if (isNaN(value)) {
    value = 0
  }
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    minimumFractionDigits: 3,      
    maximumFractionDigits: 3,
    currency: UserStore.getters.getCountryCurrencyCode
  })
  return currencyFormatter.format(value)
})

Vue.filter('formatNumber', function(value) {
  if (isNaN(value)) {
    value = 0
  }
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,      
    maximumFractionDigits: 2,
  });

  return formatter.format(value)
})

Vue.filter('formatFuelVolume', function(value) {
  if (isNaN(value)) {
    value = 0
  }
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 3,      
    maximumFractionDigits: 3,
  });

  return formatter.format(value) + " gal"
})

Vue.filter('formatNumber4Decimals', function(value) {
  if (isNaN(value)) {
    value = 0
  }
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 4,      
    maximumFractionDigits: 4,
  });

  return formatter.format(value)
})

Vue.filter('formatFuelVolumeSimple', function(value) {
  if (isNaN(value)) {
    value = 0
  }
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 3,      
    maximumFractionDigits: 3,
  });

  return formatter.format(value) 
})

// Vue.filter('formatFuelVolumeSimple', function(value) {

//     const formatter = new Intl.NumberFormat('en-US', {
//       minimumFractionDigits: 3,      
//       maximumFractionDigits: 3,
//     });

//     return formatter.format(value)
// })

Vue.filter('formatUSAPhoneNumber', function(value) {
  var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
})


// Global Functions
Vue.mixin({
  methods: {

    authors() {
      return [
        {
          "name": "Aby Thomas"
        },
        {
          "name": "Saran Krishnan"
        }
      ]
    },

    showUSDateFormat(date) {
      if (date) {
        return moment(date).format('MM-DD-YYYY')
      } else {
        "N/A"
      }
      
    },

    isNarrowScreen() {
        return window.innerWidth <= 1000 ?  true :  false
    },

    mobileCheck() {
        if (
            navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/iPhone/i)
            ) 
        {
            return true
        } else {
            return false
        }          
    },

    toTitleCase(text) {
      if (!text) return " "
      return text.toLowerCase()
                  .split(' ')
                  .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                  .join(' ')
    },

    formatNumberWithCommas(num,dec) {
      num = parseFloat(num)
      dec = parseInt(dec)
      dec = dec < 1 ? 0 : dec
      // num = (num.toFixed(dec).toString)
      num = num ? num : 0
      return (num.toFixed(dec)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    nullToZero(value) {
      return (value ? value : 0)
    },

    negetiveZeroToZero(value) {
      return (Object.is(Math.sign(value), -0) ? 0 : value)
    },

    // negetiveZeroToZero(value) {
    //   if (Object.is(value, -0)) {
    //     return 0
    //   } else {
    //     return value
    //   }
    // },

    // minusZeroToZero(value) {
    //   if (1/value === -Infinity) {
    //     return 0
    //   } else {
    //     return value
    //   }
    // },

    getNumber(inp) {
      if (Number(inp)) {
          return parseFloat(inp)
      }
      return 0
    },
    
    groupArrayByProp(arr, prop) {
      var array = this.sortArrayByProp(arr, prop, "ASC")
      const map = new Map(Array.from(array, obj => [obj[prop], []]));
      array.forEach(obj => map.get(obj[prop]).push(obj));
      return Array.from(map.values());
    },

    sortArrayByProp(array, prop, order) {
        if (!order) {
          order = "asc"
        }
        return array.slice(0).sort(function(a,b) {
          if (order == "asc") {
            return (a[prop] > b[prop]) ? 1 : (a[prop] < b[prop]) ? -1 : 0;
          } else {
            return (b[prop] > a[prop]) ? 1 : (b[prop] < a[prop]) ? -1 : 0;
          }

        })
    },
        
    allowNumbersOnly(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },

    sumArrayProp(arr, prop) {
      return arr.reduce((accum,item) => accum + parseFloat(item[prop] ? item[prop] : 0.00), 0)
    },

    activeStoreId() {
      return UserStore.getters.getActiveStore.storeId
    },

    activeStoreName() {
      return UserStore.getters.getActiveStore.storeName
    },

    getSetting(setting) {
      var value = null
      if (UserStore.getters.getStoreSettingsValue(setting)) {
        value = UserStore.getters.getStoreSettingsValue(setting).value
      }
      return value
    },

    getSettingEmails(setting) {
      if (UserStore.getters.getStoreSettingsValue(setting) !== undefined) {
        if (UserStore.getters.getStoreSettingsValue(setting).value) {
          return UserStore.getters.getStoreSettingsValue(setting).value.replace(/(\r\n|\n|\r)/gm,",").split(',').filter(n => n)    
        }
      } else {
        return []
      }
    },

    getAuth() {
      return UserStore.getters.isAuth
    },

    getUser() {
        return UserStore.getters.getUser
    },

    getUserName() {
        return UserStore.getters.getUserName
    },

    getCountryCurrencyCode() {
      return UserStore.getters.getCountryCurrencyCode
    },

    getSettingUserAuthorized(setting) {
      var valuesString = (UserStore.getters.getStoreSettingsValue(setting).value)
      if (valuesString) {
        valuesString = valuesString.replace(/(\r\n|\n|\r)/gm,",")
        var valuesArray = valuesString.split(',').filter(n => n)
        var value = valuesArray.find(element => element === UserStore.getters.getUserEmail)
        if (value) return true
      }
      if (UserStore.getters.getUserEmail === 'aby@tsits.com' || UserStore.getters.getUserEmail === 'support@veecli.com') return true
      return false
    },

    getDivWidth(div) {
      if (document.getElementById(div) !== null) {
          return document.getElementById(div).clientWidth
      }
      return null
    },

    getWindowWidth() {
      return window.innerWidth
    },

    merchMarginSalesPrice(cost, percentage) {
      return (cost / (1 - (percentage / 100))).toFixed(2) * 1
    },

    merchMarkupSalesPrice(cost, percentage) {
      return (cost * (1 + (percentage / 100))).toFixed(2) * 1
    },

    calculateMercandiseCost(revenue, percentage) {
      var cost = 0
      if (this.getSetting('profitCalculationMethod') == 'Margin') {
        cost= (revenue * (1 - (percentage / 100))).toFixed(2)
      } else {
        cost= (revenue/(1 + (percentage / 100))).toFixed(2)
      }
      return cost
    },
    
    calculateMerchandiseProfit(cost, salePrice,deptPerc) {
      if (this.getSetting('profitCalculationMethod') == 'Margin') {
        if (cost && salePrice) {
          return this.getNumber(((salePrice - cost) / salePrice * 100).toFixed(2))
        }
      } else {
        if (cost && salePrice) {
          return this.getNumber(((salePrice - cost) / cost * 100).toFixed(2))
        }
      }
      return deptPerc
    },

    calculateMercandiseSalePrice(cost, deptPerc) {
      var salePrice = 0
      if (this.getSetting('profitCalculationMethod') == 'Margin') {
        salePrice = (cost / (1 - (deptPerc / 100))).toFixed(2)
      } else {
        salePrice = (cost * (1 + (deptPerc / 100))).toFixed(2)
      }
      return salePrice * 1
    },

    zeroPad(num, places) {
      return String(num).padStart(places, '0')
    },

    sysIdListToIdArray(cfgArray, sysIdList) {
      if (!sysIdList) sysIdList = ""
      if (!cfgArray) return []
      var sysIdArray = sysIdList.split(',')
      var idArray = []
      sysIdArray.forEach(sysId => {
          var cfgItem = cfgArray.find((o => o.sysid == sysId))
          if (cfgItem) {
              idArray.push(cfgItem.id)
          }
      })
      return idArray
    },

    idArrayToSysIdList(cfgArray, idArray) {
      if (!idArray) idArray = []
      if (!cfgArray) return ""
      var sysIdList = ""
      idArray.forEach(id => {
          var cfgItem = cfgArray.find((o => o.id == id))
          if (cfgItem) {
              if (sysIdList) {
                sysIdList = sysIdList + "," + cfgItem.sysid.toString()
              } else {
                sysIdList = cfgItem.sysid.toString()
              }
          }
      })
      return  sysIdList
    },

    isOverflowWidth(elt) {
      return elt.scrollWidth > elt.offsetWidth;
    },

    isOverflowHeight(elt) {
      return elt.scrollHeight > elt.offsetHeight;
    },

    formatDate(value) {
      return moment(value).format('MM/DD/YYYY')
    },

    sendEmail(to,subject,message) {
        
        const url = process.env.VUE_APP_API_ROOT_URL + 'stores/' + this.user.activeStore 
                        + '/email'

        if (!(to && subject && message)) {
          if (process.env.VUE_APP_DEBUG == 'true') console.log("Invalid Email Request. No Email Send")
          return "Invalid Email Request. No Email Send"
        }

        to.forEach(email => {
          if (!this.validateEmail(email)) {
            if (process.env.VUE_APP_DEBUG == 'true') console.log("Invalid Email Address. No Email Send", email)
            return "Invalid Email Address. No Email Send"
          }
        })
        
        var emailMessage = {
              "from": "notification@veecli.com",
              "to": to.toString(),
              "subject": subject,
              "text": "Please use an HTML Capable Email Client to view the email.",
              "html": message
        }

        axios.post(url,
        emailMessage,
            {
                headers: {
                    'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                    'Refresh-Token': UserStore.getters.getRefreshToken
                },                   
            })
            .then( (response) => {
              if (process.env.VUE_APP_DEBUG == 'true') console.log(response)
            })
    },

    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },

    setCookie(name,value,seconds) {
      var days = -1
      if (seconds > -1) {
        days =  this.getNumber(seconds) *24 * 60 * 60        
      }

      const d = new Date();
      d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
      let expires = "expires=" + d.toUTCString();

      document.cookie = name.trim() + "=" + value.trim() + ";SameSite=Strict; "+expires +";path=/;domain=" + window.location.hostname
    },

    removeCookie(name) {
      this.setCookie(name,"",-1);
    },

    getCookie(name) {
      let cookieArr = document.cookie.split(";");
      for(let i = 0; i < cookieArr.length; i++) {
        let cookiePair = cookieArr[i].split("=");
        
        if(name == cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1]);
        }
      }
      return null
    },

    setPageCookie(pageName,version,validDays) {
      if (!validDays) validDays = 30
      if (!version) version = this.getAppVersion()
      if (this.getCookie(pageName) == version) {
        return false
      } else {
          this.setCookie(pageName,version,validDays)
          return true
      }
    },

    getAppVersion() {
      return UserStore.getters.getAppVersion
    },

    showIntro(pageName,pageVersion,introSteps) {
      if (this.setPageCookie(pageName,pageVersion)) {
        introJs().setOptions({
          isActive: this.showIntro,
          tooltipClass: 'introJsTooltip',
          steps: introSteps
        }).start()
      }
    },

    forceIntro(introSteps) {
      introJs().setOptions({
        isActive: true,
        tooltipClass: 'introJsTooltip',
        steps: introSteps
      }).start()
    },
      
  },

  computed: {
    isLeftMenuAllowed() {
      return UserStore.getters.getActiveStore.role != 'PLATFORM_FUEL_DATA'
    },
    isStoresMenuAllowed() {
      return UserStore.getters.getActiveStore.role != 'PLATFORM_FUEL_DATA'
    },
  }
})

import VueProgressBar from 'vue-progressbar'
// import { scaleService } from 'chart.js';

Vue.use(VueProgressBar, {
  color: 'rgb(143, 255, 199)',
  failedColor: 'red',
  height: '2px'
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
